<template>
  <div>
    <v-select
      label="text"
      :options="options"
      v-model="rowData.bedID"
      :reduce="text => text.value"
      :clearable="false"
      :searchable="false"
      :disabled="disabled"
    >
      <template #no-options>
        {{$t('common.no_options')}}
      </template>
    </v-select>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import common from "@/common";

export default {
  name: "SlotBed",
  components: {
    vSelect
  },
  props: {
    rowData: {type: Object},
    roomId: {type: Number},
    disabled: {type: Boolean, default: false}
  },
  data() {
    return {
      options: []
    }
  },
  watch: {
    roomId: function (roomID, oldRoomID) {
      if (oldRoomID) {
        this.rowData.bedID = null
        this.options = []
      }

      this.getBedOptions(roomID)
    }
  },
  methods: {
    getBedOptions: function (room_id) {
      common.getBedOptions(room_id).then(res => {
        this.options = res
      })
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
