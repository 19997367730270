import common from "@/common";

export default [
  {
    name: 'companyName',
    label: 'common.company',
    type: 'text',
    show: common.isAdmin(),
    edit_disable: true
  },
  {
    name: 'facilityName',
    label: 'common.facility',
    type: 'text',
    slot: 'facility',
    show: common.isAdmin(),
    edit_disable: true
  },
  {
    name: 'floorName',
    label: 'common.floor',
    type: 'text',
    slot: 'floor',
    edit_disable: true
  },
  {
    name: 'roomName',
    label: 'common.room',
    type: 'text',
    edit_disable: true
  },
  {
    name: 'bedNumber',
    label: 'common.bed',
    type: 'text',
    edit_disable: true
  },
  {
    name: 'memberCode',
    label: 'member.memberCode',
    type: 'text',
    edit_show: true,
    edit_disable: true
  },
  // {
  //   name: 'refCode',
  //   label: 'member.refCode',
  //   type: 'text',
  //   // rule: 'required|max: 20',
  //   edit_disable: true
  // },
  {
    name: 'chineseName',
    label: 'member.chineseName',
    type: 'text',
    edit_disable: true
  },
  {
    name: 'englishName',
    label: 'member.englishName',
    type: 'text',
    edit_disable: true
  },
  {
    name: 'eHealthMemberCode',
    label: 'kiosk.ehealth_member_code',
    rule: 'required',
    type: 'text',
  },

]
