<template>
  <div style="padding: 0.6rem 1rem; border: 1px solid #aaa; border-radius: 0.357rem;">
    <b-avatar size="100px" :src="getPhoto" class="default-photo-bg" />
    <b-button variant="outline-primary" class="ml-2" @click="$refs.refPhoto.$el.click()">{{$t('common.select')}}</b-button>
    <b-form-file
      ref="refPhoto"
      accept=".jpg, .png, .jpeg"
      :hidden="true"
      plain
      @input="inputPhoto"
    />
  </div>
</template>

<script>
import { BAvatar, BButton, BFormFile } from 'bootstrap-vue'
import common from "@/common";

export default {
  name: "SlotPhoto",
  components: {
    BAvatar,
    BButton,
    BFormFile
  },
  props: {
    rowData: {type: Object},
    isEdit: {type: Boolean},
    rowDataPhoto: {type: String}
  },
  data() {
    return {
      defaultPhoto: require('@/assets/images/user.png'),
      photo: '',
    }
  },
  computed: {
    getPhoto: function () {
      if (this.photo) {
        return this.photo
      } else if (this.rowData.photo) {
        return common.getServerUrl() + this.rowData.photo
      } else {
        return this.defaultPhoto
      }
    }
  },
  methods: {
    inputPhoto: function (file) {
      const that = this
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = function () {
        that.photo = reader.result

        if (that.isEdit) {
          common.showConfirm(common.getI18n('member.updatePhotoTips'), '', function () {
            common.apiPostData('/memberphoto/UpdatePhoto', {
              facilityID: common.getFacilityId(),
              memberID: that.rowData.memberID,
              photo: that.photo
            })
          })
        } else {
          that.rowData['photo'] = that.photo
        }
      }
    },
  }
}
</script>

<style scoped>

</style>
